var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{},[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{attrs:{"elevation":"3","max-width":""}},[_c('v-card-text',[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.$store.state.globalMonth,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.$store.state, "globalMonth", $event)},"update:return-value":function($event){return _vm.$set(_vm.$store.state, "globalMonth", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Mostrando información del mes:","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.formatDate),callback:function ($$v) {_vm.formatDate=$$v},expression:"formatDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalMes),callback:function ($$v) {_vm.modalMes=$$v},expression:"modalMes"}},[_c('v-date-picker',{attrs:{"type":"month","locale":"es"},model:{value:(_vm.$store.state.globalMonth),callback:function ($$v) {_vm.$set(_vm.$store.state, "globalMonth", $$v)},expression:"$store.state.globalMonth"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":function($event){_vm.modalMes = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"green"},on:{"click":function($event){_vm.$refs.dialog.save(_vm.$store.state.globalMonth), _vm.main()}}},[_vm._v(" Mostrar ")])],1)],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Búscar piloto","single-line":"","hide-details":""},model:{value:(_vm.search_piloto),callback:function ($$v) {_vm.search_piloto=$$v},expression:"search_piloto"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Búscar placa","single-line":"","hide-details":""},model:{value:(_vm.search_placa),callback:function ($$v) {_vm.search_placa=$$v},expression:"search_placa"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-dialog',{ref:"diaDialog",attrs:{"return-value":_vm.dialogVarSelectedDia,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.dialogVarSelectedDia=$event},"update:return-value":function($event){_vm.dialogVarSelectedDia=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Dias:","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.selectedDias),callback:function ($$v) {_vm.selectedDias=$$v},expression:"selectedDias"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalFecha),callback:function ($$v) {_vm.modalFecha=$$v},expression:"modalFecha"}},[_c('v-date-picker',{attrs:{"selected-items-text":'$vuetify.datePicker.itemsSelected',"locale":"es","range":true,"scrollable":"","min":_vm.dialogVarMinDia,"max":_vm.dialogVarMaxDia},model:{value:(_vm.dialogVarSelectedDia),callback:function ($$v) {_vm.dialogVarSelectedDia=$$v},expression:"dialogVarSelectedDia"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalFecha = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.diaDialog.save(_vm.dialogVarSelectedDia)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-btn',{attrs:{"small":""},on:{"click":function($event){_vm.page = 1;
                  _vm.fnLoad_manifiestos();}}},[_vm._v(" Búscar ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c('v-pagination',{attrs:{"length":_vm.totalPages,"total-visible":"7","next-icon":"mdi-menu-right","prev-icon":"mdi-menu-left"},on:{"input":_vm.handlePageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"loading":_vm.loadingTBL,"disable-sort":_vm.loadingTBL,"headers":_vm.headers,"items":_vm.tblRegs,"options":_vm.options,"server-items-length":_vm.tblRegs.length,"disable-pagination":"","hide-default-footer":true,"no-data-text":"Sin manifiestos que mostrar","no-results-text":"No se encontrarón manifiestos"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item._id.manifiesto",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({on:{"click":function($event){return _vm.copyToClipBoard(item._id.manifiesto)},"mouseover":function($event){item.hover = true},"mouseleave":function($event){item.hover = false}}},'span',attrs,false),on),[_vm._v(_vm._s(_vm._f("truncate")(item._id.manifiesto,6, '...')))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item._id.manifiesto))])])]}},{key:"item.porcentaje",fn:function(ref){
                  var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(
                    _vm.fnPorcentajeCalculado(
                      item.entregados,
                      item.anulados,
                      item.devueltos,
                      item.totalGuias
                    )
                  ),"dark":""}},[_vm._v(" "+_vm._s(_vm.fnPorcentajeCalculado( item.entregados, item.anulados, item.devueltos, item.totalGuias ))+"% ")])]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.fn_imprimir_manifiesto(item)}}},[_vm._v(" mdi-printer ")])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4","sm":"3"}},[_c('v-select',{attrs:{"items":_vm.pageSizes,"label":"Guías por página"},on:{"change":function($event){return _vm.handlePageSizeChange(_vm.pageSize)}},model:{value:(_vm.pageSize),callback:function ($$v) {_vm.pageSize=$$v},expression:"pageSize"}})],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12","sm":"9"}},[_c('span',{staticClass:"mt-3"},[_vm._v("Total de manifieistos: "+_vm._s(_vm.totalRecords))]),_c('v-pagination',{attrs:{"length":_vm.totalPages,"total-visible":"7","next-icon":"mdi-menu-right","prev-icon":"mdi-menu-left"},on:{"input":_vm.handlePageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }