<template>
  <v-container class="">
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <v-row>
              <v-col cols="4">
                <!-- /** inicio selector de mes*/ -->
                <v-card class="" elevation="3" max-width="">
                  <v-card-text>
                    <v-dialog
                      ref="dialog"
                      v-model="modalMes"
                      :return-value.sync="$store.state.globalMonth"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="formatDate"
                          label="Mostrando información del mes:"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <!-- /*** DASHBOARD SELECT FECHA DATERANGE MES */ -->
                      <v-date-picker
                        v-model="$store.state.globalMonth"
                        type="month"
                        locale="es"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="red" @click="modalMes = false">
                          Cancelar
                        </v-btn>
                        <v-btn
                          text
                          color="green"
                          @click="
                            $refs.dialog.save($store.state.globalMonth), main()
                          "
                        >
                          Mostrar
                        </v-btn>
                      </v-date-picker>
                      <!-- /*** DASHBOARD SELECT FECHA DATERANGE MES */ -->
                    </v-dialog>
                  </v-card-text>
                </v-card>
                <!-- /** fin selector de mes*/ -->
              </v-col>
            </v-row>
          </v-card-title>
          <!-- /**
          parametros de búsqueda Y PAGINADOR
          *
          *
           */ -->
          <v-col cols="12" sm="12">
            <v-row>
              <v-col cols="3">
                <v-text-field
                  v-model="search_piloto"
                  append-icon="mdi-magnify"
                  label="Búscar piloto"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="search_placa"
                  append-icon="mdi-magnify"
                  label="Búscar placa"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <!-- /*** Dashboard select - dias */ -->
                <v-dialog
                  ref="diaDialog"
                  v-model="modalFecha"
                  :return-value.sync="dialogVarSelectedDia"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="selectedDias"
                      label="Dias:"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :selected-items-text="'$vuetify.datePicker.itemsSelected'"
                    locale="es"
                    v-model="dialogVarSelectedDia"
                    :range="true"
                    scrollable
                    :min="dialogVarMinDia"
                    :max="dialogVarMaxDia"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modalFecha = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.diaDialog.save(dialogVarSelectedDia)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
                <!-- /*** Dashboard select - dias */ -->
              </v-col>
              <v-col cols="12" sm="2">
                <v-btn
                  small
                  @click="
                    page = 1;
                    fnLoad_manifiestos();
                  "
                >
                  Búscar
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <!-- <v-col cols="4" sm="3">
                <v-select
                  v-model="pageSize"
                  :items="pageSizes"
                  label="Guías por página"
                  @change="handlePageSizeChange(pageSize)"
                ></v-select>
              </v-col> -->

              <v-col cols="12" class="d-flex justify-end">
                <v-pagination
                  v-model="page"
                  :length="totalPages"
                  total-visible="7"
                  next-icon="mdi-menu-right"
                  prev-icon="mdi-menu-left"
                  @input="handlePageChange"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-col>
          <!-- /**
          parametros de búsqueda Y PAGINADOR
          *
          *
           */ -->
          <!-- /** tabla de manifiestos */ -->
          <v-col cols="12">
            <v-data-table
              :loading="loadingTBL"
              :disable-sort="loadingTBL"
              :headers="headers"
              :items="tblRegs"
              :options.sync="options"
              :server-items-length="tblRegs.length"
              disable-pagination
              :hide-default-footer="true"
              no-data-text="Sin manifiestos que mostrar"
              no-results-text="No se encontrarón manifiestos"
            >
              <template v-slot:[`item._id.manifiesto`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      @click="copyToClipBoard(item._id.manifiesto)"
                      @mouseover="item.hover = true"
                      @mouseleave="item.hover = false"
                      v-bind="attrs"
                      v-on="on"
                      >{{ item._id.manifiesto | truncate(6, '...') }}</span
                    >
                  </template>
                  <span>{{ item._id.manifiesto }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.porcentaje`]="{ item }">
                <v-chip
                  :color="
                    getColor(
                      fnPorcentajeCalculado(
                        item.entregados,
                        item.anulados,
                        item.devueltos,
                        item.totalGuias
                      )
                    )
                  "
                  dark
                >
                  {{
                    fnPorcentajeCalculado(
                      item.entregados,
                      item.anulados,
                      item.devueltos,
                      item.totalGuias
                    )
                  }}%
                </v-chip>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click="fn_imprimir_manifiesto(item)">
                  mdi-printer
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
          <!-- /** tabla de manifiestos */ -->
          <!-- /**
          datos de la tabla */ -->
          <v-col cols="12" sm="12">
            <v-row>
              <v-col cols="4" sm="3">
                <v-select
                  v-model="pageSize"
                  :items="pageSizes"
                  label="Guías por página"
                  @change="handlePageSizeChange(pageSize)"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="9" class="d-flex justify-end">
                <span class="mt-3"
                  >Total de manifieistos: {{ totalRecords }}</span
                >
                <v-pagination
                  v-model="page"
                  :length="totalPages"
                  total-visible="7"
                  next-icon="mdi-menu-right"
                  prev-icon="mdi-menu-left"
                  @input="handlePageChange"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-col>
          <!-- /**
          datos de la tabla */ -->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import axios from 'axios';
import { imprimir_manifiesto_js } from './../manifiestos/imprimir_manifiesto';
export default {
  data() {
    return {
      search_piloto: '',
      search_placa: '',
      /**
       * day picker inicio
       */
      dialogVarSelectedDia: null,
      modalMes: false,
      modalMes: false,
      modalFecha: false,
      selectedDias: [
        moment().startOf('month').format('DD-MM-YYYY'),
        moment().format('DD-MM-YYYY'),
      ],
      /**
       * day picker fin
       */
      /** monthPicker data fin */
      search: '',
      options: {},
      loadingTBL: false,
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: '_id.manifiesto',
        },
        {
          text: 'Piloto',
          align: 'start',
          sortable: false,
          value: '_id.piloto',
        },
        {
          text: 'Placa',
          align: 'start',
          sortable: false,
          value: '_id.placa',
        },
        {
          text: 'fecha',
          align: 'start',
          sortable: false,
          value: '_id.fecha',
        },
        { text: 'Total de guias', value: 'totalGuias', sortable: false },
        { text: 'En ruta', value: 'asignados', sortable: false },
        { text: 'Entregados', value: 'entregados', sortable: false },
        { text: 'Anulados', value: 'anulados', sortable: false },
        { text: 'Con devolución', value: 'devueltos', sortable: false },
        {
          text: 'Porcentaje',
          align: 'center',
          value: 'porcentaje',
          sortable: false,
        },
        {
          text: 'manifiesto',
          align: 'center',
          value: 'actions',
          sortable: false,
        },
      ],
      tblRegs: [],
      /** */
      totalRecords: 0,
      page: 1,
      totalPages: 0,
      pageSize: 5,
      pageSizes: [5, 15, 50],
    };
  },
  methods: {
    copyToClipBoard(textToCopy) {
      this.$notify({
        group: 'auth',
        type: 'notify',
        title: 'Copiado al portapapeles',
        text: `ID de manifiesto: ${textToCopy}`,
        duration: 3000,
      });
      navigator.clipboard.writeText(textToCopy);
    },
    fn_imprimir_manifiesto(item) {
      console.log(item);
      this.findguias({
        query: this.getParams2(item.totalGuias, item._id.manifiesto),
      }).then((result) => {
        console.log(result);
        item.guias = result.data;
        imprimir_manifiesto_js(item);
      });
    },
    handlePageChange(value) {
      this.page = value;
      this.fnLoad_manifiestos();
    },
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.fnLoad_manifiestos();
    },
    ...mapActions('DashboardManifiestos', {
      findDashboardManifiestos: 'find',
    }),
    ...mapActions('Gpsguias', {
      getguias: 'get',
      findguias: 'find',
      patchGuia: 'patch',
      crearGuia: 'create',
    }),
    getColor(porcentaje) {
      if (porcentaje < 40) return 'red';
      else if (porcentaje < 70) return 'orange';
      else return 'green';
    },
    fnPorcentajeCalculado(entregados, devueltos, anulados, totalGuias) {
      let porcentaje = (
        ((entregados + anulados + devueltos) / totalGuias) *
        100
      ).toFixed(2);
      return porcentaje;
    },
    getParams2(total, id_manifiesto) {
      /**
       * configuración de parametros
       */
      const params = {};

      /**
       *
       * parametros
       */

      if (id_manifiesto != '') {
        params['quienCarga.pilotoManifiesto'] = id_manifiesto;
      }

      // let split1 = this.selectedDias[0].split('-');
      // // prettier-ignore
      // let firstDay = new Date(split1[2],split1[1] - 1,split1[0] - 1,17,59,59);

      // let split2 = this.selectedDias[1].split('-');
      // let lastDay = new Date(split2[2], split2[1] - 1, split2[0], 17, 59, 59);

      // //
      // params['quienCarga.fecha'] = {
      //   $gte: firstDay,
      //   $lte: lastDay,
      // };

      params['$sort'] = { 'quienCarga.fecha': -1 };

      //
      params['$limit'] = total;

      /**
       *
       * fin parametros
       */

      /**
       *
       * retorno de parametros
       */

      return params;
    },
    getParams() {
      /**
       * configuración de parametros
       */
      const params = {};

      /**
       *
       * parametros
       */
      if (this.search_piloto != '') {
        params['piloto'] = this.search_piloto;
      }
      if (this.search_placa != '') {
        params['placa'] = this.search_placa;
      }

      let split1 = this.selectedDias[0].split('-');
      // prettier-ignore
      let firstDay = new Date(split1[2],split1[1] - 1,split1[0] - 1,17,59,59);

      let split2 = this.selectedDias[1].split('-');
      let lastDay = new Date(split2[2], split2[1] - 1, split2[0], 17, 59, 59);

      //
      params['fecha_dias'] = {
        firstDay: firstDay,
        lastDay: lastDay,
      };
      //
      params['fecha'] = this.$store.state.globalMonth;

      /**
       * $SORT param
       */
      if (
        this.options.sortBy &&
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        let sorting = {};
        let flag = this.options.sortDesc[0] ? 1 : -1;
        sorting[this.options.sortBy[0].toString()] = flag;
        params['$sort'] = sorting;
      } else {
        params['$sort'] = { createdAt: -1 };
      }

      /**
       * paramentros de búsqueda por paginación (obligatorios)
       */
      params['$limit'] = this.pageSize;
      params['$skip'] = (this.page - 1) * this.pageSize;

      /**
       *
       * fin parametros
       */

      /**
       *
       * retorno de parametros
       */

      console.log(params);

      return params;
    },
    async fnLoad_manifiestos() {
      // prettier-ignore
      let url = `${process.env.VUE_APP_FEATHERSJS_SERVER_API}dashboard-manifiestos`;
      await axios({
        method: 'get',
        url: url,
        headers: {
          Authorization:
            'Bearer  ' + window.localStorage.getItem('feathers-jwt'),
        },
        params: this.getParams(),
      }).then((response) => {
        /**
         *
         * registros
         */
        this.tblRegs = response.data.registros;

        /**
         *
         * paginado
         */
        this.totalPages = Math.ceil(
          parseInt(response.data.total) / parseInt(this.pageSize)
        );
        /**
         *
         * total de registros
         */
        this.totalRecords = response.data.total;
      });
    },
    main() {
      this.fnLoad_manifiestos();
    },
  },
  computed: {
    formatDate() {
      return this.$store.state.globalMonth
        ? moment(this.$store.state.globalMonth).format('MMMM-YYYY')
        : '';
    },
    //
    selectedMes() {
      return this.$store.state.globalMonth
        ? moment(this.$store.state.globalMonth).format('MMMM-YYYY')
        : moment().format('MMMM-YYYY');
    },
    dialogVarMinDia() {
      if (this.$store.state.globalMonth) {
        return moment(this.$store.state.globalMonth)
          .startOf('month')
          .format('YYYY-MM-DD');
      } else {
        return moment().startOf('month').format('YYYY-MM-DD');
      }
    },
    dialogVarMaxDia() {
      if (this.$store.state.globalMonth) {
        return moment(this.$store.state.globalMonth)
          .endOf('month')
          .format('YYYY-MM-DD');
      } else {
        return moment().format('YYYY-MM-DD');
      }
    },
  },
  watch: {
    '$store.state.globalMonth': function (newValue) {
      this.selectedDias = [
        moment(newValue).startOf('month').format('DD-MM-YYYY'),
        moment(newValue).endOf('month').format('DD-MM-YYYY'),
      ];
      this.dialogVarSelectedDia = [
        moment(newValue).startOf('month').format('YYYY-MM-DD'),
        moment(newValue).endOf('month').format('YYYY-MM-DD'),
      ];
    },
    dialogVarSelectedDia(newValue) {
      this.selectedDias = [
        moment(newValue[0]).format('DD-MM-YYYY'),
        moment(newValue[1]).format('DD-MM-YYYY'),
      ];
    },
    options: {
      handler() {
        this.fnLoad_manifiestos();
      },
      deep: true,
    },
  },
  mounted() {
    this.main();
  },
};
</script>
<style scoped>
.v-application a {
  color: #ffffff00 !important;
}
</style>
